<script>
  import Icons from "@/components/reuseables/Icons.vue";

  export default {
    name: "MenuComponent",
    components: {
      Icons
    },
    props: {
      menuTitle: {
        type: [String],
        default: "dashboard"
      },
      hasSubMenu: {
        type: [Boolean],
        default: false
      },

      subMenu: Array,
      selectedMenu: {
        type: [String],
        default: "dashboard"
      },
      activeSubMenu: [String]
    },

    data() {
      return {
        showSubMenu: false,
        selectedSubMenu: ""
      }
    },
    methods: {
      handleShowSubMenu() {
        this.showSubMenu = !this.showSubMenu;
      },
      handleSelectedMenu(menuTitle) {
        this.$emit("handleSelectedTab", menuTitle, this.hasSubMenu)
      },
      handleSubMenuClick(subMenuTitle) {
        this.selectedSubMenu = subMenuTitle;
        let subMenu = {}
        subMenu.menu = this.menuTitle
        subMenu.subMenu = subMenuTitle
        this.$emit("handleSelectedSubMenu", subMenu)
      }
    },
  }
</script>
<template>
  <div :class="[selectedMenu === menuTitle ? 'menu-selected': 'menu' ]">
    <div class="tw-flex tw-w-full tw-flex-col" @click="handleSelectedMenu(menuTitle)">
      <div
        class="tw-flex tw-w-full tw-flex-row tw-items-center tw-py-5 tw-px-8 tw-justify-between tw-cursor-pointer menu"
        @click="handleShowSubMenu">

        <div class="tw-flex tw-flex-row tw-items-center tw-w-auto tw-cursor-pointer">
          <icons :name="menuTitle" />
          <label class="pl-2 text-capitalize"
            :class="[selectedMenu === menuTitle ? 'menu-title-selected': 'menu-title' ]">{{menuTitle}}</label>
        </div>
        <icons :name="showSubMenu ? 'chevron-down' :  'chevron-right'" v-if="hasSubMenu" />
      </div>
      <v-expand-transition>
        <div class="tw-flex tw-w-full tw-flex-col submenu-container " v-if="showSubMenu">
          <div class="tw-flex tw-flex-row tw-items-center tw-w-auto tw-py-3 tw-px-12 submenu-item"
            :class="[selectedSubMenu === subMenuTitle ? 'selected-sub-menu' : 'submenu-item']"
            v-for="(subMenuTitle,index) in subMenu" :key="index" @dblclick="handleSubMenuClick(subMenuTitle)">
            <div :class="[selectedSubMenu === subMenuTitle ? 'selected-circle' : 'circle']" />
            <label class="pl-2 text-capitalize"
              :class="[selectedSubMenu === subMenuTitle ? 'selected-sub-menu-text' : 'submenu-item-text']">{{subMenuTitle}}</label>
          </div>
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>
<style scoped lang="scss">
  .menu-title {
    color: #4F4F4F;
    font-size: 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .menu-title-selected {
    color: #4F4F4F;
    font-size: 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    //border-right: 2px solid #004aad;
  }

  .menu:hover {
    background: #f5f7f9;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: flex-end;
    border-left: 20px;
    // border-right: 1px solid #004aad;
  }


  .menu-selected {
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: flex-end;
    border-left: 20px;
    border-right: 3px solid #004aad;
  }


  // .show-stroke:hover{
  //  display: flex;
  //  //justify-content: space-between;
  //  padding: 0;
  //  width: 3px;
  //  background-color: #004aad;
  //  height: auto;
  //  align-items: flex-end;
  //  border-right: 20px;
  //  margin-right: 10%;
  // }

  .circle {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #B5B6B6;
  }

  .selected-circle {
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background-color: #004aad;
  }

  .general {
    font-family: Inter sans-serif;
    font-size: 17px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: #988d8d;
    margin: 10px 0 20px 18px;
  }

  .submenu-container:hover {
    width: 100%;
  }

  .submenu-item {
    cursor: pointer;
    color: rgba(131, 131, 131, 0.59);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .selected-sub-menu {
    background: #f5f7f9;
    border-radius: 10px;
    background: rgba(0, 74, 173, 0.10);
    margin-right: 20px;
    padding-left: 1.7rem;
    margin-left: 20px;
  }

  .selected-sub-menu-text {
    color: #004aad !important;
    font-size: 15px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .submenu-text {
    color: rgba(131, 131, 131, 0.59);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
</style>