<template>
  <v-navigation-drawer class="side-menu" app>
    <!--      <div-->
    <!--          :class="selectedTab === tab ? 'selected' : 'select-div'"-->
    <!--          class="my-3 ml-9 tw-cursor-pointer"-->
    <!--          @click="handleSelectedTab(tab)"-->
    <!--          v-for="(tab, index) in tabs"-->
    <!--          :key="index"-->
    <!--      >-->
    <!--        <v-icon v-if="tab === selectedTab" :color="companyColor"-->
    <!--        >mdi-power-on</v-icon-->
    <!--        >-->
    <!--        <icons-->
    <!--            :name="tab"-->
    <!--            :color="selectedTab === tab ? companyColor : '#4F4F4F'"-->
    <!--        />-->
    <!--        <label-->
    <!--            class="pl-4 text-capitalize"-->
    <!--            :style="{ color: selectedTab === tab ? companyColor : '#4F4F4F' }"-->
    <!--        >{{ tab }}</label-->
    <!--        >-->
    <!--      </div>-->
    <v-app-bar fixed flat class="top-section ml-4">
      <img class="tw-cursor-pointer" src="https://res.cloudinary.com/myroadpadi/image/upload/v1702625774/production_asset/blue-logo_oucnpq.png" alt="company logo" width="130"
        @click="$router.push({ name: 'Paddler' })" />
    </v-app-bar>
    <div class="tw-mt-20">
      <div class="subheading">
        <h2>General</h2>
      </div>

      <menu-component :menu-title="menu.name" v-for="(menu, index) in generalFeatures" :key="index"
        :has-sub-menu="menu.hasSubMenu" :selected-menu="selectedTab" @handleSelectedTab="handleSelectedTab"
        :sub-menu="menu.subMenu" />
      <div class="subheading">
        <h2>Others</h2>
      </div>
      <menu-component :menu-title="menu.name" v-for="(menu, otherIndex) in otherFeatures" :key="otherIndex + 'other'"
        :has-sub-menu="menu.hasSubMenu" :selected-menu="selectedTab" @handleSelectedTab="handleSelectedTab"
        :sub-menu="menu.subMenu" :active-sub-menu="selectedSubMenu" @handleSelectedSubMenu="handleSelectedSubMenu">
      </menu-component>
    </div>
    <div class="search-id-div" style="border-radius: 10px; border: 1.5px solid rgba(79, 79, 79, 0.08)">
      <v-text-field placeholder="Search payment ID" color="#004aad" class="p-0 tf-styles" solo flat dense hide-details
        v-model="search" @keydown.enter.prevent="handleSearch">
        <template #prepend-inner>
          <icons class="mt-1 mr-2" name="atm" color="#939393" />
        </template>
      </v-text-field>
    </div>

    <v-divider class="my-5"></v-divider>
    <div class="tw-flex  tw-items-center ml-7 mt-3 mb-10 tw-cursor-pointer" @click="handleLogout()">
      <icons color="#004aad" name="logout" />
      <span class="pl-3 text-capitalize" style=" color: #004aad ">logout</span>
    </div>
  </v-navigation-drawer>
</template>

<script>
  import Icons from "./Icons";
  import {
    mapGetters
  } from "vuex";
  import {
    logout
  } from "@/services/api/AuthApiService";
  import MenuComponent from "@/components/reuseables/MenuComponent.vue";
  export default {
    name: "SideMenu",
    components: {
      MenuComponent,
      Icons
    },
    props: {
      companyColor: {
        type: [String],
        default: "#004AAD"
      }
    },
    data() {
      return {
        search: "",
        selectedTab: "blog",
        selectedSubMenu: "",
        generalFeatures: [{
            name: "dashboard",
            hasSubMenu: false
          },
          {
            name: "documents",
            hasSubMenu: false
          },
          {
            name: "wallet",
            hasSubMenu: false
          },
          {
            name: "feedback",
            hasSubMenu: false
          },

          {
            name: "settings",
            hasSubMenu: false
          }
        ],

        otherFeatures: [
          {
            name: "bookings",
            hasSubMenu: false,
          },
            {
            name: "paddlers",
            hasSubMenu: true,
            subMenu: ["Units", "Departments"]
          },

          {
            name: "support",
            hasSubMenu: true,
            subMenu: ["blog", "newsletter"]
          },
          {
            name: "products",
            hasSubMenu: true,
            subMenu: [
              "RIBS",
              "SKIP",
              "transport companies",
              "water transport",
              "mobile agents",
              "corporate agents",
              "corporate companies",
              "developers",
              "insurance provider",
               "analytics"
            ]
          },

          {
            name: "finance",
            hasSubMenu: true,
            subMenu: ["finance", "finance"]
          },

          {
            name: "marketing",
            hasSubMenu: true,
            subMenu: ["blogs", "discounts","adverts"]
          },

          {
            name: "talent & dev",
            hasSubMenu: true,
            subMenu: ["talent", "dev"]
          },
          {
            name: "project mgt.",
            hasSubMenu: true,
            subMenu: ["project", "mgt"]
          },
          {
            name: "procurement",
            hasSubMenu: true,
            subMenu: ["procurement", "procurement"]
          },
          {
            name: "inventory mgt.",
            hasSubMenu: true,
            subMenu: ["inventory", "inventory"]
          },
          {
            name: "payments",
            hasSubMenu: false
          }
        ]
      };
    },
    computed: {
      ...mapGetters("menu", ["selectedMenu"]),
      routeName() {
        return this.$route.name;
      }
    },
    methods: {
      handleSelectedTab(tab, hasSubMenu) {
        if (!hasSubMenu) {
          sessionStorage.removeItem("selectedSubMenu")
        }
        this.selectedTab = tab;
        if (this.selectedTab === "dashboard") {
          if (this.$route.name !== "Dashboard")
            this.$router.push({
              name: "Dashboard"
            });
        } else if (this.selectedTab === "wallet") {
          if (this.$route.name !== "Wallet")
            this.$router.push({
              name: "Wallet"
            });
        } else if (this.selectedTab === "bookings") {
          if (this.$route.name !== "Bookings")
            this.$router.push({
              name: "Bookings"
            });
        } else if (this.selectedTab === "payments") {
          if (this.$route.name !== "PaymentDashboard")
            this.$router.push({
              name: "PaymentDashboard"
            });
        } 
        else if (this.selectedTab === "products") {
          if (this.$route.name !== "ProductDashboard")
            this.$router.push({
              name: "ProductDashboard"
            });
        } 
        else if (this.selectedTab === "pos" && this.$route.params.requestId) {
          if (this.$route.name !== "ApprovePos")
            this.$router.push({
              name: "ApprovePos"
            });
        } else if (this.selectedTab === "pos") {
          if (this.$route.name !== "PosDashboard")
            this.$router.push({
              name: "PosDashboard"
            });
        } else if (this.selectedTab === "marketing") {
          if (
            this.$route.name !== "MarketingDept" &&
            this.$route.name !== "AllBlogPost" &&
            this.$route.name !== "NewBlogPost"
          )
            this.$router.push({
              name: "MarketingDept"
            });
        } else if (this.selectedTab === "paddlers") {
          if (this.$route.name !== "AllPaddlers")
            this.$router.push({
              name: "AllPaddlers"
            });
        } else if (this.selectedTab === "monitoring") {
          if (this.$route.name !== "PaymentLog")
            this.$router.push({
              name: "PaymentLog"
            });
        } else if (this.selectedTab === "waitlist") {
          if (this.$route.name !== "Waitlist")
            this.$router.push({
              name: "Waitlist"
            });
        }

        sessionStorage.setItem("selectedMenu", tab);
        this.handleSetCurrentMenu();
      },
      handleSetCurrentMenu() {
        let menu = sessionStorage.getItem("selectedMenu");
        this.$store.dispatch("menu/setSelectedMenu", menu);
      },
      handleSelectedSubMenu(subMenu) {
        sessionStorage.setItem("selectedSubMenu", JSON.stringify(subMenu))
        this.selectedSubMenu = subMenu.subMenu
        if (subMenu.subMenu === "Units") {
          if (this.$route.name !== "AllUnits") {
            this.$router.push({
              name: "AllUnits"
            });
          }
        } else if (subMenu.subMenu === "Departments") {
          if (this.$route.name !== 'AllDepartments')
            this.$router.push({
              name: "AllDepartments"
            });
        } else if (subMenu.subMenu === "RIBS") {
          if (this.$route.name !== 'RibsDashboard')
            this.$router.push({
              name: "RibsDashboard"
            });
        } else if (subMenu.subMenu === "SKIP") {
          if (this.$route.name !== 'SkipDashboard')
            this.$router.push({
              name: "SkipDashboard"
            });
        } else if (subMenu.subMenu === "transport companies") {
          if (this.$route.name !== "transportCompanyVerify")
            this.$router.push({
              name: "transportCompanyVerify"
            });
        } else if (subMenu.subMenu === "water operators") {
          if (this.$route.name !== "WaterTransportOperator")
            this.$router.push({
              name: "WaterTransportOperator"
            });
        } else if (subMenu.subMenu === "corporate agent") {
          if (this.$route.name !== "CorporateAgent")
            this.$router.push({
              name: "CorporateAgent"
            });
        } else if (subMenu.subMenu === "developers") {
          if (this.$route.name !== "Developers")
            this.$router.push({
              name: "Developers"
            });
        } else if (subMenu.subMenu === "mobile agents") {
          if (this.$route.name !== "MobileAgent")
            this.$router.push({
              name: "MobileAgent"
            });
        } else if (subMenu.subMenu === "corporate companies") {
          if (this.$route.name !== "CorporateOrganization")
            this.$router.push({
              name: "CorporateOrganization"
            });
        }
        else if (subMenu.subMenu === "insurance provider"){
          if (this.$route.name !== "InsuranceProviderVerify"){
            this.$router.push({name: "InsuranceProviderVerify"})
          }
        }

        else if (subMenu.subMenu === "newsletter"){
          if (this.$route.name !== "NewsLetter")
            this.$router.push({
              name: "NewsLetter"
            });
        }
        else if (subMenu.subMenu === "discounts"){
          if (this.$route.name !== "Discounts")
            this.$router.push({
              name: "Discounts"
            });
        }
        else if (subMenu.subMenu === "analytics"){
          if (this.$route.name !== "ProductAnalytics")
            this.$router.push({
              name: "ProductAnalytics"
            });
        }
        else if (subMenu.subMenu === "adverts"){
          if (this.$route.name !== "Adverts")
            this.$router.push({
              name: "Adverts"
            });
        }
      },
      handleLogout() {
        logout();
      },
      handleSearch(id) {
        this.search = id.target._value;
        this.$store.dispatch("paymentLog/setSearchedPaymentId", this.search);
        if (this.$route.name !== "PaymentDashboard") {
          this.$router.push({
            name: "PaymentDashboard"
          });
        }
      }
    },
    created() {
      let selectedMenu = sessionStorage.getItem("selectedMenu");
      let selectedSubMenu = sessionStorage.getItem("selectedSubMenu")
      if (selectedSubMenu) {
        selectedSubMenu = JSON.parse(selectedSubMenu)
        this.selectedTab = selectedSubMenu.menu
        sessionStorage.setItem("selectedMenu", selectedSubMenu.menu)
        this.$store.dispatch("menu/setSelectedMenu", selectedSubMenu.menu)
        this.handleSelectedTab(this.selectedTab);
        this.selectedSubMenu = selectedSubMenu.subMenu
      } else if (!selectedMenu) {
        sessionStorage.setItem("selectedMenu", "dashboard");
        this.handleSetCurrentMenu();
      } else {
        this.selectedTab = selectedMenu;
        this.$store.dispatch("menu/setSelectedMenu", selectedMenu);

        this.handleSelectedTab(this.selectedTab);
      }
    }
  };
</script>

<style scoped lang="scss">
  .top-section {
    padding-top: 10px;
    padding-bottom: 5px;
    height: fit-content !important;
    background-color: #ffffff !important;
  }

  .side-menu {
    display: flex;
    background: #ffffff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-direction: column;
    max-height: 100vh !important;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  ::v-deep .v-navigation-drawer__content::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  ::v-deep .v-navigation-drawer__border {
    display: none !important;
  }

  .select-div {
    height: 50px;
    background: white;
    width: auto;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: center;
    font-family: Inter, serif;
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    color: #4f4f4f;
    flex-direction: row;
  }

  .select-div:hover {
    height: 50px;
    background: #f5f7f9;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: center;
  }

  .selected {
    height: 50px;
    background: #f5f7f9;
    width: 100%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    display: flex;
    align-items: center;
  }

  .subheading {
    font-family: Inter sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    text-align: left;
    color: rgba(79, 79, 79, 0.35);
    margin: 10px 0 20px 18px;
  }

  .search-id-div {
    width: inherit;
    margin: 10px 30px auto;

    .v-input {
      width: 100%;
    }
  }
</style>