<template>
  <v-app-bar elevation="0" app style="background: #FFFFFF" height="75">
    <div class="tw-flex tw-w-full tw-items-center tw-justify-center">
      <span class="username">Hi {{logInPaddler.firstname}}!</span>
      <v-spacer></v-spacer>

      <icons class="tw-cursor-pointer" name="setting" color="#4f4f4f"></icons>
      <div class="tw-px-7">
        <v-badge color="#004aad" content="2">
          <icons class="tw-cursor-pointer" name="bell"></icons>
        </v-badge>

      </div>
      <div>
        <v-menu rounded bottom offset-y min-width="200px">
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-avatar size="40">
                <v-img cover
                  :src=" logInPaddler.profileImage ? logInPaddler.profileImage : ' https://randomuser.me/api/portraits/women/60.jpg'">
                </v-img>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item class="" @click="viewProfile()">
              <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                View profile
              </v-list-item-title>
            </v-list-item>
            <v-list-item class="" @click="handleLogout()">
              <v-list-item-title style="color: black" class="tw-flex-row tw-flex tw-cursor-pointer lighter-text">
                Logout
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <!-- <mobile-avatar /> -->
    <!-- <mobile-side-menu /> -->
  </v-app-bar>
</template>

<script>
  import {
    logout
  } from "../../services/api/AuthApiService";
  import Icons from './Icons.vue';
  import {
    mapGetters
  } from "vuex";
  // import MobileAvatar from "./MobileAvatar";
  // import MobileSideMenu from "./MobileSideMenu";

  export default {
    components: {
      Icons
    },
    name: "NavBar",
    // components: {MobileSideMenu, MobileAvatar},
    props: {
      companyColor: {
        type: [String],
        default: "#004AAD"
      },
      companyEmail: [String],
      defaultName: [String],
      companyLogo: [String]
    },
    data() {
      return {
        items: ["Profile", "Logout"],
        drawer: false,
        user: {
          initials: 'JD',
          fullName: 'John Doe',
          email: 'john.doe@doe.com',
        }
      };

    },
    computed: {
      ...mapGetters("paddler", ["logInPaddler"])
    },
    methods: {
      selectedAction(item) {
        if (item === 'Logout') {
          this.handleLogout()
        }
      },
      handleLogout() {
        logout()
      },
      viewProfile(){
        // console.log(this.logInPaddler)
        this.$router.push({name:"ViewProfile"})
      }
    },

  }
</script>

<style scoped lang="scss">
  ::v-deep .v-toolbar__content {
    padding: 4px 3rem;
  }
    .lighter-text {
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #838383;
    }
  .username {
    color: #4F4F4F;
    font-family: Inter;
    font-size: 17px;
    font-weight: 500;
  }

  .logo-col {
    @media screen and (min-width: 770px) {
      padding-left: 3rem;
    }
  }

  .v-badge {
    display: block;
  }
</style>