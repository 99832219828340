<template>
<v-app id="dashboard">
    <nav-bar/>
    <side-menu/>
    <v-main>
      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </v-main>
</v-app>
</template>

<script>
import NavBar from "../components/reuseables/NavBar";
import SideMenu from "../components/reuseables/SideMenu";
export default {
  name: "DashboardView",
  components: { SideMenu, NavBar},
  async created() {
    await this.$store.dispatch("paddler/setLoginPaddlerData")
  }
}
</script>

<style scoped lang="scss">

#dashboard{
  min-height: 100vh;
}
</style>